<template>
    <div class="setting">
        <div class="left_account">
            <el-form>
                <div><p style="font-size: large">{{$t('message.email.currentaccount')}}</p></div>
                <div class="card" v-if="currentId != ''">
                    <accountcard @deleteaccount="deleteaccount" :dataObj="emailAccountForm" :temp="1"></accountcard>
                </div>
                <div class="card" v-else>
                    <accountcard @deleteaccount="deleteaccount" :dataObj="accountandemail[0]"></accountcard>
                </div>
                <div><p style="font-size: large">{{$t('message.email.accountlist')}}</p></div>
              <div style="overflow-y: auto;height: 500px;">
                <ul>
                  <li v-for="(item,index) in accountandemail" :key="item.index">
                    <accountcard  @deleteaccount="deleteaccount" v-if="item.id != emailAccountForm.id" :dataObj="item"></accountcard>
                  </li></ul>
              </div>
            </el-form>
        </div>
        <div class="right_set">
            <el-form :rules="rules">
                <div><p style="font-size: large">{{$t('message.email.mailsetting')}}</p></div>
                <div>
                    <el-card class="box-card" :class="[isActive == -1 ?'change':'changs']">
                        <template #header>
                            <div class="card-header">
                                <p style="font-size: larger;">{{$t('message.email.Internetmailsetting')}}</p>
                                <p>&emsp;&emsp;{{$t('message.email.sentence4')}}</p>
                            </div>
                        </template>
                        <el-row>
                            <div class="right_box">
                                <div class="col_box">
                                    <el-row>
                                        <p style="font-size: large">{{$t('message.email.userinformation')}}</p>
                                    </el-row>
                                    <el-row>

<!--                                        <el-col :span="12">
                                            <el-form-item :label="$t('message.email.accountname')" label-width="150px">
                                                <el-input v-model="emailAccountForm.account_nickname" style="width: 70%" >
                                                </el-input>
                                            </el-form-item>
                                        </el-col>-->
                                        <el-col  :span="12">
                                            <el-form-item :label="$t('message.email.mailaddress')" label-width="150px" prop="account_email">
                                                <el-input v-model="emailAccountForm.account_email" style="width:100%" >
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                      <el-col  :span="12">
                                        <el-form-item :label="$t('message.email.Password')" label-width="150px">
                                          <el-input v-model="emailAccountForm.login_passwd" type="password" show-password clearable style="width:90%" >
                                          </el-input>
                                        </el-form-item>
                                      </el-col>
                                    </el-row>
                                </div>
                                <div class="col_box">
                                    <el-row>
                                        <p style="font-size: large">{{$t('message.email.serverinformation')}}</p>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item :label="$t('message.email.mailservertype')" label-width="180px">
                                                <el-select v-model="emailAccountForm.mail_server_provider"   style="width: 80%">
                                                    <el-option
                                                            v-for="item in servertype"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col  :span="12">
                                            <el-form-item :label="$t('message.email.accounttype')" label-width="180px">
                                                <el-select v-model="emailAccountForm.server_protocol_type"  @change="servertype_port" style="width: 80%">
                                                    <el-option
                                                            v-for="item in accounttype"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="10">
                                            <el-form-item :label="$t('message.email.IncomingServer')" label-width="180px">
                                                <el-input v-model="emailAccountForm.incoming_server_port" style="width: 90%" >
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2">
                                            <el-form-item>
                                                <el-checkbox v-model="emailAccountForm.is_ssl" @change="isssl" label="SSL"></el-checkbox>
                                            </el-form-item>
                                        </el-col>
<!--                                        <el-col :span="12">-->
<!--                                            <el-form-item :label="$t('message.email.IncomingMailServer')" label-width="200px">-->
<!--                                                <el-input v-model="emailAccountForm.incoming_mail_server" style="width: 80%" >-->
<!--                                                </el-input>-->
<!--                                            </el-form-item>-->
<!--                                        </el-col>-->
                                      <el-col  :span="12">
                                        <el-form-item :label="$t('message.email.IncomingMailServer')" label-width="200px">
                                          <el-input v-if="this.emailAccountForm.mail_server_provider=='Custom'"  v-model="emailAccountForm.incoming_mail_server" style="width: 80%" >
                                          </el-input>
                                          <el-input v-else v-model="incoming_mail_server" style="width: 80%" >
                                          </el-input>
                                        </el-form-item>
                                      </el-col>
                                        <!--<el-col :span="6">-->
                                        <!--<el-button size="mini" style="margin-top: 5px">{{$t('message.email.Usedefault')}}</el-button>-->
                                        <!--</el-col>-->
                                    </el-row>
                                </div>
                            </div>
                            <div  class="right_box">
                                <el-row>
<!--                                    <el-col :span="12">-->
<!--                                        <div class="col_box">-->
<!--                                            <el-row>-->
<!--                                                <p style="font-size: large">{{$t('message.email.ServerTimeoutSetting')}}</p>-->
<!--                                            </el-row>-->
<!--                                            <el-row>-->
<!--                                                <el-col>-->
<!--                                                    <el-form-item label-width="50px">-->
<!--                                                        <el-row>-->
<!--                                                            <el-col :span="18"><el-slider v-model="timeout" :max="3"></el-slider></el-col>-->
<!--                                                            <el-col :span="1"></el-col>-->
<!--                                                            <el-col :span="5">{{timeout}}{{$t('message.email.min')}}</el-col>-->
<!--                                                        </el-row>-->
<!--                                                    </el-form-item>-->
<!--                                                </el-col>-->
<!--                                            </el-row>-->
<!--                                          <el-row>-->
<!--                                            <div style="margin: 5%; background-color: white">-->
<!--&lt;!&ndash;                                              <p>&emsp;&emsp;{{$t('message.email.sentence6')}}</p>&ndash;&gt;-->
<!--                                              <p>超过此时间如未得到响应，您需要重新测试。</p>-->
<!--                                            </div>-->
<!--                                          </el-row>-->
<!--                                        </div>-->
<!--                                    </el-col>-->
                                    <el-col>
                                        <div class="col_box">
                                            <el-row>
                                                <p style="font-size: large">{{$t('message.email.AccountTesting')}}</p>
                                            </el-row>
                                            <el-row>
                                                <div style="margin: 1%; background-color: white">
                                                    <p>&emsp;&emsp;{{$t('message.email.sentence6')}}</p>
                                                </div>
                                            </el-row>
                                            <el-row>
                                                <el-button size="mini" style="margin-left:73%;margin-bottom: 10px" @click="connecttest">{{$t('message.email.TestAccountSetting')}}</el-button>
                                            </el-row>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>

                            <div style="margin-left: 90%;height: auto;">
                                <el-row>
                                    <el-button @click="updateUserAccount">{{$t('message.save')}}</el-button>
                                </el-row>
                            </div>

                        </el-row>
                    </el-card>
                </div>
            </el-form>
        </div>

    </div>
</template>

<script>
    import accountcard from '../../components/account_email/Accountcard'
    import {getinfouseraccount,getemailaccount,updateuseraccount,connectaccount} from '../../api/emailextract'
  export default {
     components:{accountcard},
      data(){
        const validatesenderEmail = (rule,value,callback) => {
          if (this.emailAccountForm.account_email !== '') {
            var reg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/
            if (!reg.test(this.emailAccountForm.account_email)) {
              callback(new Error(window.vm.$t('message.email.please_email')));
            } else {
              callback();
            }
          } else {
            callback();
          }
        };
        return{
          timeout:0,
          account_email:{
            account:'',
            email:''
          },
          currentId:'',
          currentUser:{},
          accountandemail:[],
          emailAccountForm:{
            account_nickname:'',
            account_email:'',
            server_protocol_type:'',
            mail_server_provider:'',
            incoming_mail_server:'',
            username:'',
            login_passwd:'',
            server_timeout:'',
            incoming_server_port:'',
            jiami:'',
            layout:''
          },
          servertype:[
            {
              value: 'Custom',
              label: 'Custom'
            },
            {
              value:  'Gmail',
              label: 'Gmail'
            },
            {
              value: 'Outlook',
              label: 'Outlook'
            },
            {
              value: 'Yahoo',
              label: 'Yahoo'
            },
            {
              value: 'HANTOS',
              label: 'HANTOS'
            },
          ],
          accounttype:[
            {
                value:'POP3',
              label:'POP3'
            },
            {
              value:'IMAP',
              label:'IMAP',
            }

          ],

          bar:'',
          src:'../../src/assets/addaccount.png',
          isActive: -1,
          rules:{
            account_email:[{validator:validatesenderEmail,trigger: ['blur','change']}]
          }
        }
      },
    computed:{
      incoming_mail_server:function() {
        if(this.emailAccountForm.server_protocol_type == 'IMAP'){
          if(this.emailAccountForm.mail_server_provider == 'QQ'){
            return 'imap.qq.com'
          }
          else if(this.emailAccountForm.mail_server_provider == '163'){
            return 'imap.163.com'
          }else if(this.emailAccountForm.mail_server_provider == 'YaHoo'){
            return 'imap.mail.yahoo.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Outlook'){
            return 'imap.outlook.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Gmail'){
            return 'imap.gmail.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Custom'){
            return ''
          }else if(this.emailAccountForm.mail_server_provider == 'dylnet'){
            return 'imap.dylnet.com'
          }
        }
        else if(this.emailAccountForm.server_protocol_type == 'POP3'){
          if(this.emailAccountForm.mail_server_provider == 'QQ'){
            return 'pop.qq.com'
          }
          else if(this.emailAccountForm.mail_server_provider == '163'){
            return 'pop.163.com'
          }else if(this.emailAccountForm.mail_server_provider == 'YaHoo'){
            return 'pop.mail.yahoo.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Outlook'){
            return 'pop.outlook.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Gmail'){
            return 'pop.gmail.com'
          }else if(this.emailAccountForm.mail_server_provider == 'Custom'){
            return ''
          }
        }
      }
    },
    methods:{

      deleteaccount(){
        this.getMailList()
      },
      addaccount(){
        this.isActive = 1
      },
      isssl(value){
        // console.log(value)
        if(value == true){
          if(this.emailAccountForm.server_protocol_type == 'IMAP') this.emailAccountForm.incoming_server_port = 993
          else if(this.emailAccountForm.server_protocol_type == 'POP3') this.emailAccountForm.incoming_server_port = 995
        }
        else if(value == false){
          if(this.emailAccountForm.server_protocol_type == 'IMAP') this.emailAccountForm.incoming_server_port = 143
          else if(this.emailAccountForm.server_protocol_type == 'POP3') this.emailAccountForm.incoming_server_port = 110
        }
      },
      servertype_port(value){
        if(this.emailAccountForm.is_ssl == true){
          if(value == 'IMAP') this.emailAccountForm.incoming_server_port = 993
          else if(value == 'POP3') this.emailAccountForm.incoming_server_port = 995
        }
        else if(this.emailAccountForm.is_ssl == false){
          if(value == 'IMAP') this.emailAccountForm.incoming_server_port = 143
          else if(value == 'POP3') this.emailAccountForm.incoming_server_port = 110
        }
      },
      submit(){
        this.isActive = -1
      },
      getMailList(){
        getemailaccount().then(response =>{
          if(response.error_code == 200){
            this.accountandemail = response.data.results
          }
        })
      },
      connecttest(){
        if(this.emailAccountForm.mail_server_provider!='Custom'){
          this.emailAccountForm.incoming_mail_server = this.incoming_mail_server
        }
        this.emailAccountForm.server_timeout = 60 * this.timeout
        connectaccount(this.emailAccountForm).then(response =>{
          if(response.error_code == 200){
            if(response.data.status==1){
              this.$message.success(response.data)
            }else{
              this.$message.warning(response.data)
            }
          }else if (response.error_code==500){
            this.$message.warning(response.data)
          }
        })
      },
        async updateUserAccount(){
        if(this.emailAccountForm.mail_server_provider!='Custom'){
          this.emailAccountForm.incoming_mail_server = this.incoming_mail_server
        }
        this.emailAccountForm.server_timeout = 60 * this.timeout
        await updateuseraccount(this.emailAccountForm).then(response =>{
          if(response.error_code == 200){
           this.$message.success('账户邮箱保存成功')
           // this.getMailList()
            this.visible = false
          }
        })
       // await
      }
    },
    created(){
      this.getMailList()
      //console.log(this.accountandemail)
       this.currentId = this.$route.params.id
     // console.log(this.currentId == '')
      if(this.currentId != ''){
        getinfouseraccount(this.currentId).then(response =>{
          if(response.error_code == 200){
            this.emailAccountForm = response.data
          }
        })
      }
    }
  };
</script>

<style lang="scss">
.setting{
    height: 100%;
    width: 100%;
    .el-input__inner{
        height:30px;
    }
    .left_account{
        font-size: larger;
        float: left;
        width: 35%;
        height: 100%;
        .btn_box{
            margin-top: 5px;
            margin-left:50px ;
        }
        .card{
            margin-bottom: 5%;
        }
    }
    .right_set{
        margin-left: 33%;
        width: 60%;
        height: 100%;
      .right_box{
        width: 100%;
      }
        .el-card__header{
            padding: 11px;
        }
        .change {
            border: white;
        }
        .changs {
           border: 5px solid #409EFF;
        }
        .el-card__body{
            padding: 10px;
        }
        .col_box{
            margin: 3%;
            background-color: rgba(212, 219, 208, 0.28);
        }
    }

}
</style>
